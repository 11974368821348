import React, { useEffect, useState } from 'react'
import { Link, navigate } from 'gatsby'
import cx from 'classnames'
import { find } from 'lodash'
import jump from 'jump.js'
import { animate, motion, AnimatePresence } from 'framer-motion'

import BlockContent from '@sanity/block-content-to-react'

import { Serializer } from 'src/utils/serializer'
import { RenderModules } from 'src/utils/renderModules'

import { viewCollection } from 'src/components/analytics'

import { useSetPage, useStore } from 'src/context/siteContext'
import { SEO } from 'src/components/SEO'
import slugify from 'slugify'

export const filterTypes = {
  'dietary': [
    { title: 'Gluten Free - GF', type:'dietary', value: 'gluten-free' },
    { title: 'Dairy Free - DF', type:'dietary', value: 'dairy-free' },
    { title: 'Vegetarian - V', type:'dietary', value: 'vegetarian' },
    { title: 'Vegan - VG', type:'dietary', value: 'vegan' }
  ],
  'serving': [
    { title: '1-2', type: 'serving', value: 'oneTwo' },
    { title: '3-4', type: 'serving', value: 'threeFour' },
    { title: '5+', type: 'serving', value: 'fivePlus' }
  ],
  'form': [
    { title: 'powder', type: 'form', value: 'powder' },
    { title: 'whole', type: 'form', value: 'whole' },
    { title: 'flake', type: 'form', value: 'flake' },
    { title: 'Cut & Sifted', type: 'form', value: 'chopped' },
    { title: 'granule', type: 'form', value: 'granule' }
  ],
  'composition': [
    { title: 'Pure Spice', value: 'pure-spice' },
    { title: 'Spice Blend', value: 'spice-blend' }
  ]
}

import {
  Plus
} from 'src/components/svgs'

export const filterTitle ={
  'dietary': 'Dietary Restrictions',
  'serving': 'Serving Size',
  'form': 'Form',
  'composition': 'Composition'
}

export interface CollectionProps {
  pageContext: {
    modules: []
    pageModules: []
    filters: []
    slug: string
    title: string
    meta: {}
  }
  path: string
  preview?: boolean
}

const Collection = ({
  path,
  pageContext,
  preview = false
}: CollectionProps) => {
  const [stateFilter, setStateFilters] = useState([])
  const [empty, setEmpty] = useState(false)
  const [showShop, toggleShowShop] = useState(false)
  const [showFilter, toggleShowFilter] = useState(false)
  const [expanded, setExpanded] = useState(false)

  const setPage = useSetPage()

  const {
    title,
    modules,
    pageModules,
    filters,
    defaultMeta,
    content,
    slug,
    meta
  } = pageContext

  // Fire Analytics
  viewCollection(
    {title},
    // filteredProducts.map(item => ({
    //   sku: item.variants[0].sku,
    //   title: item.title,
    //   variantTitle: item.variants[0].title,
    //   cents: item.cents,
    // })),
  )

  const jumpy = (jString) => {
    jump(`.js-${slugify(jString, { lower: true })}`, {
      offset: -52,
      // callback: () => {
      //   console.log('what?')
      // },
      
    })
  }

  const nestedNav = (modules) => {
    return (
      <div className='collection__menu-nested'>
        {modules.map((module, i) => {
          if (module._type === 'productGrid') {
            return <button onClick={() => jumpy(module.title)} key={i} className='db bn ml1 courier s14 bg--transparent'>{module.title}</button>
          }
        })}
      </div>
    )
  }

  const handleSelection = (item: {}) => {
    // Check if item is in the state first
    const arrayState = [...stateFilter]
    const index = arrayState.indexOf(item)

    if (index !== -1) {
      setStateFilters(stateFilter.filter((e) => (e !== item)))
    } else {
      setStateFilters([...arrayState, item])
    }
  }

  useEffect(() => {
    setPage('collection')
  }, [])

  useEffect(() => {
    const collectionGrid = document.querySelector('.collection__grid')
    const domCols = collectionGrid?.querySelectorAll('.product__card-col')
    if (stateFilter.length > 0) {
      setEmpty(true)
      domCols?.forEach(dom => {
        stateFilter.forEach(filter => {
          if (dom.classList.contains(filter.value)) {
            setEmpty(false)
          }
        })
      })
    } else {
      setEmpty(false)
    }

  }, [stateFilter])

  const shopByMobile = (
    <div className='mt1 mb1'>
      <Link activeClassName='active' className='db founders mb1 pr s22' to='/collections/recipes'>Recipes</Link>
      <Link activeClassName='active' className='db founders mb1 pr s22' to='/collections/chefs'>Chefs</Link>
      <Link activeClassName='active' className='db founders mb1 pr s22' to='/collections/spices'>Spices</Link>
      <Link activeClassName='active' className='db founders mb1 pr s22' to='/collections/decks'>Decks</Link>
    </div>
  )

  const filterBlock = filters?.map((filter, i) => (
    <div key={i} className='mb1 mt1'>
      <div>
        <span className='s14 caps medium founders colletions__filter-headers'>{filterTitle[filter]}</span>
      </div>
      {filterTypes[filter].map((item, nestedI) => (
        <button key={nestedI} onClick={() => handleSelection(item)} className={cx('button s12 caps button--filter mt1 mr1 di', {
          'active': find(stateFilter, { 'value': item.value })
        })}>
          <span>{item.title}</span>
        </button>
      ))}
    </div>
  ))

  const shopBy = (
    <div className='mt1 mb1'>
        <button className={cx('caps db pl0 ml0 founders p0 pr medium s14', {
        'active': path.indexOf('holiday-gifts') === 13
      })} onClick={() => {
        navigate('/collections/holiday-gifts', {
          state: {disableTransition: true}
        })
        }}>Holiday Gifts</button>
      <button className={cx('caps db pl0 ml0 founders p0 pr medium s14', {
        'active': path.indexOf('recipe-kits') === 13
      })}
      onClick={() => {
        navigate('/collections/recipe-kits', {
          state: {disableTransition: true}
        })
      }}>Recipe Kits</button>
      {path.indexOf('recipe-kits') === 13 && nestedNav(modules)}
      <button className={cx('caps db pl0 ml0 founders p0 pr medium s14', {
        'active': path.indexOf('spice-cards') === 13
      })} onClick={() => {
        navigate('/collections/spice-cards', {
          state: {disableTransition: true}
        })
      }}>Spice Cards</button>
      {path.includes('spice-cards') && nestedNav(modules)}
      <button className={cx('caps db pl0 ml0 founders p0 pr medium s14', {
        'active': path.indexOf('sets') === 13
      })} onClick={() => {
        navigate('/collections/sets', {
          state: {disableTransition: true}
        })
        }}>Sets</button>
      <button className={cx('caps db pl0 ml0 founders p0 pr medium s14', {
        'active': path.indexOf('chefs') === 13
      })} onClick={() => {
        navigate('/collections/chefs', {
          state: {disableTransition: true}
        })
      }}>Chefs</button>

    </div>
  )

  const mobileFiltering = (
    <>
      <div className={cx('df collection__filtering-mobile jcb row bt bc fw gutter--none x', !filters && 'hide-border')}>
        <div className={cx('col br c7', {
          'c14': !filters
        })}>
          <button onClick={() => {
            toggleShowShop(!showShop)
            toggleShowFilter(false)
          }} className={cx('bn button bg--theme-btn df jcb aic x', {
            'rotate--plus': showShop
          })}>
            <span>Shop By</span>
            <Plus className='mr2' />
          </button>
        </div>
        <div className={cx('col c7', {
          'c0 hide':  !filters
        })}>
          <button onClick={() => {
            toggleShowFilter(!showFilter)
            toggleShowShop(false)
          }} className={cx('bn button bg--theme-btn df jcb aic x', {
            'rotate--plus': showFilter
          })}>
            <span>Filter By</span>
            <Plus className='mr2' />
          </button>
        </div>
        {showShop && (
          <div className='col collection__filtering-shopby bg--sand bb bc c14'>
            <div className='p2 ml2'>
              {shopByMobile}
            </div>
          </div>
        )}
        {showFilter && (
          <div className='col collection__filtering-filterby bg--sand bb bc c14'>
            <div className='p2 ml2'>
              {filterBlock}
            </div>
          </div>
        )}
      </div>
    </>
  )

  const desktopFiltering = (
    <div className='p2 collection__menu psy top p3--800 pt5--800'>
      <div className=''>
        <div className='show-800'>
          <h5 className='courier s14 bb pb1 collection__menu-main'>Shop By</h5>
          {shopBy}
        </div>
        {filters && (
          <div className='mt3 bt bb'>
            <button
            className='df pl0 pr0 x collection__menu-bottom-none jcb aic bt m0 p0'
            onClick={() => setExpanded(expanded === true ? false : true)}>
              <h5 className='courier pt1 s14  pb1'>Filter By</h5>
              <Plus className={cx(expanded !== false && 'rotate-45')} />
            </button>
            <AnimatePresence initial={false}>
              {expanded && (
                <motion.section
                  key="content"
                  initial="collapsed"
                  animate="open"
                  exit="collapsed"
                  variants={{
                    open: { opacity: 1, height: "auto" },
                    collapsed: { opacity: 0, height: 0 }
                  }}
                  transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                  {filterBlock}
                </motion.section>
              )}
            </AnimatePresence>
          </div>
        )}
        <div className='mt3'>
          {content.main.text && (
            <BlockContent blocks={content.main.text} serializers={Serializer} />
          )}
        </div>
      </div>
    </div>
  )

  const filtersEnabled = (
    <div className='col psy collection__filtering-enabled z4 bl c14 bg--white'>
      <div className='p2 pl3 df jcb aic'>
        <div className='df jcs aic'>
          <h3 className='mr1 mr3--800 '>Filters</h3>
          <div className='hide--800'>
            {stateFilter && (
              <span className='h3'>({stateFilter.length})</span>
            )}
          </div>
          <div className='show--800 x df'>
            {stateFilter.map((s, i) => (
              <button key={i} onClick={() => handleSelection(s)} className='button caps button--filter mt1 mr1 df aic'>
                <span>{s.title}</span>
                <div className='collection__grid-circle df jcc aic'>
                  <svg width="10" height="10" viewBox="0 0 10 10" fill="none">
                    <path d="M2.45481 7.40248L7.40252 2.45477" stroke="#191818" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M2.47353 2.47423L7.38368 7.38439" stroke="#191818" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
              </button>
            ))}
          </div>
        </div>
        <div>
          <button type='reset' onClick={() => setStateFilters([])} className='founders button--none collection__clear caps s16 link-underline nb bg--transparent'>Clear All</button>
        </div>
      </div>
    </div>
  )

  return (
    <div className='x ac'>
      <SEO defaultMeta={defaultMeta} defaultTitle={title} metaInfo={meta} pagePath={`/collections/${slug}`} />
      <div className='bg--green df jcs y pl3--800 pr3--800 text__hero aie'>
      <div className="outer mxa p2 pb3 pt6  pb2--800 tl x">
        <h1 className='s72 color--white'>
          <span className='saol italic'>Just what you need,</span><br />
          <span className='founders'>as fresh as can be.</span></h1>
      </div>
    </div>
      {stateFilter.length > 0 && (
        <div dangerouslySetInnerHTML={{
          __html: `
              <style>
              .collection__grid.row .product__card-col {
                display: none;
              }
                ${stateFilter.map(filter => (
                  `.collection__grid.row .product__card-col.${filter.value} {
                    display: inline-block;
                  }` 
                )).join(' ')}
              </style>
            `
        }} />
      )}
      <div className='row pr bg--off-white color--black product__grid gutter--none df fw jcb'>
        <div className='col c14 psy z5 collection__filtering-mobile top hide--800'>
          {mobileFiltering}
          {stateFilter.length > 0 && (
              <>
                {filtersEnabled}
              </>
            )}
        </div>
        <div className='col bt c3 show--800'>
          {desktopFiltering}
        </div>
        <div className='x col c14 c11--800'>
          <div className={cx('row gutter--none df collection__grid pr fw', stateFilter.length > 0 && 'filtering')}>
            {stateFilter.length > 0 && (
              <div className='show--800 x'>
                {filtersEnabled}
              </div>
            )}
            {!empty ? RenderModules(modules) : (
              <div className='col c14 bl collection__empty x tc '>
                <div className='df jcc aic y'>
                  <h2>
                    Nothing to see here
                  </h2>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='collection__modules'>
        {RenderModules(pageModules)}
      </div>
    </div>
  )
}

export default Collection